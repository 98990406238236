export const environment = {
  production: true,
  baseApiUrl: 'https://ivicon-matrix-api-dev.azurewebsites.net',
  auth0: {
    domain: 'dev-nd4gtjxtar7xqa45.us.auth0.com',
    clientId: 'lwdfTUE2yQsoJzJMXlWBwkG0uNyGp4x5',
    redirectUri: window.location.origin,
    audience: 'https://api.dev.ivicongroup.com'
  },
  blobUrl: 'https://campimage.blob.core.windows.net',
  mapid: '3ed250267a97de49',
  oKey: 'sk-proj-CnZSam3Q1A8XTDlh6RSeT3BlbkFJ8XfMfVxFqqRddFKvUoZC'
};
