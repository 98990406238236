import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { EducationProgram } from '../models/program';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ProgramService {
  private baseUrl = `${environment.baseApiUrl}/api/v1/programs`;
  constructor(private http:HttpClient) { }

  getPrograms(): Observable<EducationProgram[]>{
    return this.http.get<EducationProgram[]>(this.baseUrl);
  }

  getProgramById(id: string): Observable<EducationProgram> {
    return this.http.get<EducationProgram>(`${this.baseUrl}/${id}`);
  }

  createProgram(value : EducationProgram) {
    return this.http.post(this.baseUrl, value);
  }

}
